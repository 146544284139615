<template>
  <div class="container">
    <div class="overlay"></div> <!-- Overlay to darken the background -->
    <div class="login">
      <h1>Welcome!</h1>
      <form @submit.prevent="loginUser">
        <div class="input-container">
                    <input type="text" v-model="username" id="username" placeholder="아이디" required />
                  </div>
        <div class="input-container">
                    <input type="password" v-model="password" id="password" placeholder="비밀번호" required />
         </div>
        <button type="submit">로그인</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    user() {
      return this.getUser;
    }
  },
  methods: {
    ...mapActions(['login']),
    async loginUser() {
      try {
        const credentials = {
          username: this.username,
          password: this.password
        };

        console.log('Attempting to log in with:', credentials);

        await this.login(credentials);

        const userRole = this.user.role;
        console.log('User role:', userRole);

        if (userRole === 'admin') {
          this.$router.push('/admin-dashboard');
        } else {
          this.$router.push('/user-dashboard');
        }
      } catch (error) {
        console.error('Login failed:', error);
        alert('Login failed');
      }
    }
  }
};
</script>

<style>
body {
  background-image: url('@/assets/background.jpg'); /* Using @ alias for src */
  background-size: cover;
  background-position: center;
  margin: 0;
  font-family: Arial, sans-serif;
}

html {
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: 1; /* Ensure the overlay is on top of the background image */
}

.login {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 2; /* Ensure the login box is on top of the overlay */
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 350px;
  text-align: center;
  border: 1px solid #ccccccb9;
  backdrop-filter: blur(10px); /* Blurs the background behind the login box */
}

.login h1 {
  text-align: center;
  margin-bottom: 35px;
  font-size: 36px;
  color: #ffffff;
}

.input-container {
  position: relative;
  margin-bottom: 25px;
}

.login input {
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  border: 1px solid #ccccccb9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  box-sizing: border-box;
  transition: border-color 0.3s;
  background-color: rgba(0, 0, 0, 0.5); /* Same semi-transparent black as login box */
  color: white; /* Ensure input text is white */
}

.login input::placeholder {
  color: #ccc; /* Light grey for placeholder text */
}

.login input:focus {
  border-color: #000000;
  outline: none;
}

.login button {
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s;
}

.login button:hover {
  background-color: #ddd; /* Slightly darker on hover */
}

</style>


