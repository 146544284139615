<!-- src/views/HomePage.vue -->
<template>
    <div>
      <h1>Home Page</h1>
      <p>Welcome to the Home Page</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
  };
  </script>
  