<template>
  <div class="overlay"></div>
  <div class="dashboard-container">
    <div class="header">
      <div class="welcome-message">
        Welcome {{ username }}
      </div>
      <button @click="logout" class="logout-btn">로그아웃</button>
    </div>

    <!-- New container for the content with a white background -->
    <div class="content-container">
      <div class="content">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>상품명</th>
              <th>검색키워드</th>
              <th>시작순위</th>
              <th>오늘순위</th>
              <th>mid 번호</th>
              <th>상품번호</th>
              <th>노트</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in rows" :key="index">
              <td>{{ index + 1 }}</td>
              <td><input type="text" v-model="item.productName" /></td>
              <td><input type="text" v-model="item.keyword" /></td>

              <!-- 시작순위 (Start Rank) -->
              <td v-if="isAdmin"><input type="text" v-model="item.startRank" /></td>
              <td v-else>{{ item.startRank }}</td>

              <!-- 오늘순위 (Today's Rank) -->
              <td v-if="isAdmin"><input type="text" v-model="item.todayRank" /></td>
              <td v-else>{{ item.todayRank }}</td>

              <td><input type="text" v-model="item.midNumber" /></td>
              <td><input type="text" v-model="item.productNumber" /></td>
              <td><input type="text" v-model="item.notes" /></td>

              <!-- Start Date -->
              <td v-if="isAdmin"><input type="date" v-model="item.startDate" /></td>
              <td v-else>{{ isValidDate(item.startDate) ? format(new Date(item.startDate), 'yyyy/MM/dd') : 'N/A' }}</td>

              <!-- End Date -->
              <td v-if="isAdmin"><input type="date" v-model="item.endDate" /></td>
              <td v-else>{{ isValidDate(item.endDate) ? format(new Date(item.endDate), 'yyyy/MM/dd') : 'N/A' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="buttons">
        <button @click="() => fileInput.click()">엑셀 업로드</button>
        <input type="file" ref="fileInput" style="display: none;" @change="uploadExcel" />
        <button @click="downloadExcel">엑셀 다운로드</button>
        <button @click="resetData">리셋하기</button>
        <button @click="saveData">저장하기</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import api from '../services/api'; // Ensure you have this service
import { format } from 'date-fns';
import { useRouter } from 'vue-router';
import store from '../store'; // Import the Vuex store

const router = useRouter(); // Initialize router properly

// Define reactive variables
const username = ref('');
const rows = ref([]); // Initialize with an empty array
const numRows = ref(10);
const isAdmin = ref(false);  // New variable to track if the user is an admin

// Function to fetch user data from the backend
const fetchUserData = async () => {
  try {
    // Get the token from local storage
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    // Make a request to the backend to get user data
    const response = await api.get('/api/user/me');

    // Print out the server response
    console.log('Server response:', response.data);

    // Set the username with the response data
    username.value = response.data.name;
    rows.value = response.data.rows;
    isAdmin.value = response.data.role === 'admin'; // Set the admin flag

    // Commit the fetched user data to the Vuex store
    store.commit('SET_USER', response.data);
  } catch (error) {
    console.error('Error fetching user data:', error);
    router.push('/login'); // Redirect to login if there's an error fetching data
  }
};

onMounted(async () => {
  console.log('UserDashboard mounted');

  const token = localStorage.getItem('token');
  console.log('Token in localStorage:', token);

  if (!token) {
    console.log('No token found, redirecting to login');
    router.push('/login');
  } else {
    console.log('Token found, dispatching setToken');
    await store.dispatch('setToken', token);
    console.log('Token set in store');

    await fetchUserData(); // Ensure user data is fetched and set before proceeding

    console.log('User data fetched and set successfully');
    // Now the user data is set, any navigation or state-dependent logic can safely follow
  }
});

watch(numRows, (newVal) => {
  // Handle the change in number of rows (e.g., update user dashboard data)
  console.log(`Number of rows set to: ${newVal}`);
});

const logout = () => {
  // Clear user authentication data (e.g., tokens)
  localStorage.removeItem('authToken'); // Example method, replace if necessary

  // Redirect to login page
  router.push('/login');
};

const fileInput = ref(null); // This is a reference to the hidden file input element

const uploadExcel = async () => {
  const file = fileInput.value.files[0];
  if (!file) {
    alert('Please select a file to upload.');
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  // Log FormData content for debugging
  formData.forEach((value, key) => {
    console.log(`FormData Key: ${key}`, 'Value:', value);
  });

  try {
    const response = await api.post('/api/save-data/upload-excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',  // Ensure the content type is set correctly
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the token in the header
      },
    });

    alert('엑셀 데이터가 성공적으로 업로드되었습니다!');
    console.log('File uploaded successfully:', response.data);

    // After the upload is successful, fetch the updated data
    await fetchUserData(); // This will refresh the dashboard with the new data

  } catch (error) {
    console.error('Error uploading file:', error);
    alert('Error uploading file: ' + (error.response?.data?.message || 'Unknown error'));
  }
};

const downloadExcel = async () => {
  try {
    const response = await api.get('/api/save-data/download-excel', {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data])); // Create a URL for the file
    const link = document.createElement('a'); // Create a temporary link element
    link.href = url;
    link.setAttribute('download', 'dashboard_data.xlsx'); // Set the file name
    document.body.appendChild(link); // Add the link to the DOM
    link.click(); // Simulate a click to start the download
    document.body.removeChild(link); // Remove the link after downloading
  } catch (error) {
    alert('Error downloading file: ' + (error.response?.data?.message || 'Unknown error')); // Show an error message if something goes wrong
  }
};

const resetData = () => {
  rows.value.forEach(row => {
    row.productName = '';
    row.keyword = '';
    row.midNumber = '';
    row.productNumber = '';
    row.notes = '';
    // Preserve these values, do not reset
    // row.startRank remains as is
    // row.todayRank remains as is
    // row.startDate remains as is
    // row.endDate remains as is
  });
};

const saveData = async () => {
  try {
    // Get the token from local storage
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    // Send the data to the backend
    const response = await api.post('/api/save-data', 
      { data: rows.value },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      alert('데이터가 성공적으로 저장되었습니다!');
    } else {
      alert('데이터 저장에 실패했습니다.');
    }
    console.log('Data saved successfully:', response);
  } catch (error) {
    console.error('Error saving data:', error);
    alert('An error occurred while saving data.');
  }
};

const isValidDate = (date) => {
  const parsedDate = new Date(date);
  return !isNaN(parsedDate);  // Checks if the parsed date is a valid number
};
</script>

<style scoped>

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  z-index: -1; /* Place it behind the content */
}

.dashboard-container {
  position: relative;
  z-index: 1; /* Ensure content is above the overlay */
  max-width: 99%;
  margin: auto;
  padding: 20px;
  }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px; /* Add padding to push the header down */
}

.welcome-message {
  font-size: 30px;
  font-weight: bold;
  color: white;
}

.logout-btn {
  background-color: #ff4d4d;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #e60000;
}

/* New content-container with white background */
.content-container {
  background-color: rgba(255, 255, 255, 0.8); /* White background with slight transparency */
  border-radius: 10px;
  padding: 20px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
}

.styled-table th, .styled-table td {
  padding: 5px 5px;
}

.styled-table th:nth-child(1),
.styled-table td:nth-child(1) {
  width: 3%; /* Adjust width for the first column */
  text-align: center;
}

.styled-table th:nth-child(2),
.styled-table td:nth-child(2) {
  width: 32%; /* Adjust width for the second column (e.g., product name) */
}

.styled-table th:nth-child(3),
.styled-table td:nth-child(3) {
  width: 10%; /* Adjust width for the third column (e.g., keyword) */
}

/* Continue adjusting widths for other columns as needed */
.styled-table th:nth-child(4),
.styled-table td:nth-child(4) {
  width: 8%; /* Example width for the fourth column */
  text-align: center;
}

.styled-table th:nth-child(5),
.styled-table td:nth-child(5) {
  width: 8%; /* Example width for the fifth column */
  text-align: center;
}

.styled-table th:nth-child(6),
.styled-table td:nth-child(6) {
  width: 10%; /* Example width for the sixth column */
}

.styled-table th:nth-child(7),
.styled-table td:nth-child(7) {
  width: 10%; /* Example width for the seventh column */
}

.styled-table th:nth-child(8),
.styled-table td:nth-child(8) {
  width: 25%; /* Example width for the eighth column */
}

.styled-table th:nth-child(9),
.styled-table td:nth-child(9) {
  width: 5%; /* Example width for the ninth column */
}

.styled-table th:nth-child(10),
.styled-table td:nth-child(10) {
  width: 5%; /* Example width for the tenth column */
}

.styled-table th {
  background-color: rgba(192, 192, 192, 0.5); /* Light grey with 50% opacity */
  color: black;
  text-align: center;
}

.styled-table tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tr:nth-of-type(even) {
  background-color: rgba(211, 211, 211, 0.3); /* Light grey with 30% opacity */
}

.styled-table tr:last-of-type {
  border-bottom: 2px solid rgba(192, 192, 192, 0.5); /* Light grey with 50% opacity */
}

.styled-table input[type="text"], 
.styled-table input[type="number"], 
.styled-table input[type="date"] {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.buttons button {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  flex-grow: 1;
  max-width: calc(90% / 4);
  color: black;
}

.buttons button:first-of-type {
  background-color: #A5D6A7; /* Pastel green */
}

.buttons button:nth-of-type(2) {
  background-color: #90CAF9; /* Pastel blue */
}

.buttons button:nth-of-type(3) {
  background-color: #EF9A9A; /* Pastel red/pink */
}

.buttons button:last-of-type {
  background-color: #FFF59D; /* Pastel yellow */
}

.buttons button:hover {
  filter: brightness(0.9); /* Darken the button on hover */
}

</style>
