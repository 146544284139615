import { createStore } from 'vuex';
import api from '../services/api'; // Use the configured Axios instance

export default createStore({
  state: {
    user: null,
    token: null,
  },
  mutations: {
    SET_USER(state, user) {
      console.log('Mutation SET_USER triggered:', user);
      state.user = user;
      console.log('State after SET_USER:', state.user);
    },
    SET_TOKEN(state, token) {
      console.log('Mutation SET_TOKEN triggered:', token);
      state.token = token;
      console.log('State after SET_TOKEN:', state.token);
    },
    LOGOUT(state) {
      console.log('Mutation LOGOUT triggered');
      state.user = null;
      state.token = null;
      console.log('State after LOGOUT:', state.user, state.token);
    }
  },
  actions: {
    async setToken({ commit }, token) {
      commit('SET_TOKEN', token); // Store the token
      console.log('Token set in store:', token);
  
      try {
        console.log('Attempting to fetch user data with token:', token);
        // Fetch the user information using the token
        const response = await api.get('/api/user/me', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Full server response:', response);
  
        // Access the user data directly
      const userData = response.data;

      // Ensure user data is valid before committing it
      if (userData && userData.role) {
        commit('SET_USER', userData); // Store the user data in Vuex
        console.log('User data committed to store:', userData);
      } else {
        console.error('Invalid user data received:', userData);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      }
    },
    async login({ commit }, credentials) {
      try {
        console.log("Login action with credentials:", credentials);
        const response = await api.post('/auth/login', credentials);
        console.log("Login action response:", response.data);
        
        const { user, token } = response.data;
        if (!user || !token) {
          throw new Error("User or token not found in response");
        }
        console.log("User from response:", user);
        console.log("Token from response:", token);
        
        // Store token in Local Storage
        localStorage.setItem('token', token);
        console.log("Token stored in Local Storage:", localStorage.getItem('token')); // Double-checking by retrieving it again
        
        // Commit the user data and token to the store
        commit('SET_USER', user);
        console.log("Committed SET_USER with user:", user);
        commit('SET_TOKEN', token);
        console.log("Committed SET_TOKEN with token:", token);
        console.log("User and token committed to store:", user, token);

      } catch (error) {
        console.error("Login error in action:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request data:", error.request);
        } else {
          console.error('Error message:', error.message);
        }
        throw error;
      }
    },
    logout({ commit }) {
      console.log('Action LOGOUT triggered');
      commit('LOGOUT');
    }
  },
  getters: {
    isAuthenticated: state => {
      const isAuthenticated = !!state.token; // Check if the token exists
      console.log('Getter isAuthenticated:', isAuthenticated);
      return isAuthenticated;
    },
    getUser: state => {
      console.log('Getter getUser:', state.user);
      return state.user;
    },
    getToken: state => {
      console.log('Getter getToken:', state.token);
      return state.token;
    },
  }
});
