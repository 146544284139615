import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
import AdminDashboard from '../views/AdminDashboard.vue';
import EditUser from '../views/EditUser.vue';
import UserDashboard from '../views/UserDashboard.vue';
import NotAuthorized from '../views/NotAuthorized.vue'; // Import NotAuthorized component
import store from '../store'; // Ensure store is imported

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '/register', name: 'Register', component: RegisterPage },

  // Admin Routes
  {
    path: '/admin-dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, role: 'admin' },
  },
  {
    path: '/admin/edit-user/:id',
    name: 'EditUser',
    component: EditUser,
    meta: { requiresAuth: true, role: 'admin' },
  },

  // User Routes
  {
    path: '/user-dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    meta: { requiresAuth: true },
  },

  // Not Authorized Route
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: NotAuthorized,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to check authentication and roles
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiredRole = to.meta.role;

  const user = store.getters.getUser;
  const isAuthenticated = store.getters.isAuthenticated;

  console.log('Navigation Guard Triggered');
  console.log('Navigating to:', to.name);
  console.log('Requires Auth:', requiresAuth);
  console.log('Required Role:', requiredRole);
  console.log('User:', user);
  console.log('Is Authenticated:', isAuthenticated);

  if (requiresAuth && !isAuthenticated) {
    console.log('User is not authenticated. Redirecting to login.');
    next('/login');
  } else if (requiresAuth && requiredRole && user.role !== requiredRole) {
    console.log('User does not have the required role. Redirecting to not authorized page.');
    next('/not-authorized');
  } else {
    console.log('User authenticated and has required role (if any). Proceeding to route.');
    next();
  }
});

export default router;
