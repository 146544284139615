<template>
    <div class="not-authorized">
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
      <router-link to="/">Go Back to Home</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotAuthorized',
  };
  </script>
  
  <style scoped>
  .not-authorized {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  