<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  created() {
    const token = localStorage.getItem('token');
    if (token) {
      this.setToken(token);
    }
  },
  methods: {
    ...mapActions(['setToken'])
  }
};
</script>

<style>
/* Add any styles here if needed */
</style>
