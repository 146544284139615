import axios from 'axios';
import store from '../store'; // Import the Vuex store to access the token

const api = axios.create({
  baseURL: 'http://188.166.227.229:3000', // my server's IP
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to include the token in the headers
api.interceptors.request.use(
  config => {
    const token = store.getters.getToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log('Token added to request headers:', token); // Log the token for debugging
    } else {
      console.log('No token found');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;
