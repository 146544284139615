<template>
  <div class="register">
    <h2>Register</h2>
    <form @submit.prevent="registerUser">
      <div>
        <label for="username">Username:</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="text" v-model="password" id="password" required />
      </div>
      <div>
        <label for="role">Role:</label>
        <select v-model="role" id="role">
          <option value="user">user</option>
          <option value="admin">admin</option>
        </select>
      </div>

      <!-- New field for rowsVisible -->
      <div>
        <label for="rowsVisible">Rows Visible:</label>
        <input 
          type="number" 
          v-model="rowsVisible" 
          @input="validateRowsVisible" 
          id="rowsVisible" 
          min="1" 
          required 
        />
      </div>

      <button type="submit">Register</button>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </form>
  </div>
</template>

<script>
import api from '../services/api'; // Import the configured API instance

export default {
  data() {
    return {
      username: '',
      password: '',
      role: 'user',
      rowsVisible: 10, // Default value for rowsVisible
      errorMessage: '' // Error message for validation
    };
  },
  methods: {
    // Method to validate the rowsVisible input
    validateRowsVisible() {
      if (this.rowsVisible < 1 || isNaN(this.rowsVisible)) {
        this.rowsVisible = 1; // Enforce a minimum of 1 row visible
      }
    },
    
    async registerUser() {
      try {
        console.log('Sending registration request with data:', {
          username: this.username,
          password: this.password,
          role: this.role,
          rowsVisible: this.rowsVisible // Include rowsVisible in the request
        });

        const response = await api.post('/auth/register', {
          username: this.username,
          password: this.password,
          role: this.role,
          rowsVisible: this.rowsVisible // Send the rowsVisible value to the backend
        });

        console.log(response.data); // Debug log
        alert('Registration successful');
        this.errorMessage = ''; // Clear any previous error message
      } catch (error) {
        console.error(error); // Debug log
        if (error.response) {
          this.errorMessage = error.response.data;
        } else if (error.request) {
          this.errorMessage = 'No response from server. Please try again later.';
        } else {
          this.errorMessage = 'An error occurred: ' + error.message;
        }
        alert('Registration failed');
      }
    }
  }
};
</script>

<style scoped>
.register {
  max-width: 300px;
  margin: auto;
}

.error-message {
  color: red;
}
</style>
