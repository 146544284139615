<template>
  <div class="overlay"></div>
  <div class="logout-container">
    <button @click="logout" class="logout-btn">Logout</button>
  </div>

  <div class="dashboard-container">
    <div class="header">
      <div class="welcome-message">
        Admin Dashboard
      </div>
    </div>

    <div class="content-container">
      <div class="content">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Username</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in filteredUsers" :key="user._id">
              <td>{{ index + 1 }}</td>
              <td>{{ user.username }}</td>
              <td>
                <!-- Edit button to navigate to EditUser page -->
                <button @click="editUser(user._id)">Edit</button>
                <button @click="confirmDelete(user._id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Display message if any -->
        <div v-if="message" class="message">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();

// State variables
const users = ref([]);
const message = ref('');

// Function to get the token from localStorage
const getToken = () => {
  return localStorage.getItem('token');
};

// Fetch the list of users when the component mounts
onMounted(() => {
  fetchUsers();
});

const fetchUsers = () => {
  axios.get('/api/admin/users', {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  })
  .then(response => {
    users.value = response.data;
  })
  .catch(error => {
    console.error('Error fetching users:', error);
    message.value = 'Failed to load users.';
    if (error.response && error.response.status === 401) {
      alert('Session expired. Please log in again.');
      router.push('/login');
    } else if (error.response && error.response.status === 403) {
      alert('Access denied. You do not have permission to view this page.');
      router.push('/not-authorized');
    }
  });
};

// Filtered users to only include those with the role "user"
const filteredUsers = computed(() => {
  return users.value.filter(user => user.role === 'user');
});

// Navigate to edit user page
const editUser = (userId) => {
  router.push({ name: 'EditUser', params: { id: userId } });
};

// Logout method
const logout = () => {
  localStorage.removeItem('token');
  router.push('/login');
};

// Confirm deletion of a user
const confirmDelete = (userId) => {
  if (confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
    deleteUser(userId);
  }
};

// Delete user method
const deleteUser = (userId) => {
  axios.delete(`/api/admin/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  })
  .then(() => {
    users.value = users.value.filter(user => user._id !== userId);
    alert('User deleted successfully.');
  })
  .catch(error => {
    console.error('Error deleting user:', error);
    alert('Failed to delete user.');
  });
};
</script>

<style scoped>
/* Your existing CSS styles */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.logout-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.logout-btn {
  background-color: #ff4d4d;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #e60000;
}

.dashboard-container {
  position: relative;
  z-index: 1;
  max-width: 99%;
  margin: auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
}

.welcome-message {
  font-size: 30px;
  font-weight: bold;
  color: white;
}

.content-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 20px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
}

.styled-table th, .styled-table td {
  padding: 12px 15px;
}

.styled-table th {
  background-color: rgba(192, 192, 192, 0.5);
  color: black;
  text-align: center;
}

.styled-table tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tr:nth-of-type(even) {
  background-color: rgba(211, 211, 211, 0.3);
}

.styled-table tr:last-of-type {
  border-bottom: 2px solid rgba(192, 192, 192, 0.5);
}

.narrow-input {
  width: 60px;
  margin-right: 5px;
}

.message {
  margin-top: 20px;
  font-size: 18px;
  color: red;
  text-align: center;
}
</style>
