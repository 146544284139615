import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import store from './store'; // Import the store

// Add this code to load the token into the Vuex store
const token = localStorage.getItem('token'); // Get the token from localStorage
if (token) {
  store.dispatch('setToken', token); // Dispatch an action to store the token in Vuex
}

const app = createApp(App);

app.use(router);
app.use(store); // Use the store
app.mount('#app');