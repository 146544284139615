<template>
  <div class="overlay"></div>

  <!-- Heading outside of the container -->
  <h2 class="page-heading" v-if="user && user.username">Edit User: {{ user.username }}</h2>
  <h2 class="page-heading" v-else>Loading User...</h2>

  <div class="edit-user-container" v-if="user && user.rows">
    <div class="content-container">
      <!-- Add Rows Section -->
      <div class="add-rows-container">
        <label for="rowCount">Number of rows to add:</label>
        <div class="input-button-wrapper">
          <input type="number" v-model="rowCount" min="1" />
          <button @click="addRows">Add Rows</button>
        </div>
      </div>

      <!-- Delete Rows Section -->
      <div class="delete-rows-container">
        <label for="deleteFrom">Delete rows from:</label>
        <input type="number" v-model="deleteFrom" min="1" placeholder="From" />
        <label for="deleteTo">to:</label>
        <input type="number" v-model="deleteTo" min="1" placeholder="To" />
        <button @click="deleteRows">Delete Rows</button>
      </div>

      <!-- Table Section -->
      <div class="rows-container">
        <h3>User Rows</h3>
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Keyword</th>
              <th>Start Rank</th>
              <th>Today Rank</th>
              <th>Mid Number</th>
              <th>Product Number</th>
              <th>Notes</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in user.rows" :key="index">
              <td>{{ index + 1 }}</td>
              <td><input type="text" v-model="row.productName" /></td>
              <td><input type="text" v-model="row.keyword" /></td>
              <td><input type="text" v-model="row.startRank" /></td>
              <td><input type="text" v-model="row.todayRank" /></td>
              <td><input type="text" v-model="row.midNumber" /></td>
              <td><input type="text" v-model="row.productNumber" /></td>
              <td><input type="text" v-model="row.notes" /></td>
              <td><input type="text" v-model="row.startDate" placeholder="YYYY-MM-DD" /></td>
              <td><input type="text" v-model="row.endDate" placeholder="YYYY-MM-DD" /></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Bottom buttons (fixed) -->
      <div class="buttons">
        <button @click="goBack">Back to Dashboard</button>
        <button @click="saveUser">Save Changes</button>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading user data...</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex'; // Import the Vuex store
import axios from 'axios';

const router = useRouter();
const route = useRoute();
const store = useStore(); // Initialize the store

const rowCount = ref(1); // Variable to track the number of rows to add
const deleteFrom = ref(1); // Variable to track "from" row number
const deleteTo = ref(1); // Variable to track "to" row number

const user = ref(null); // Initially set user to null

// Fetch the user data when the component mounts
onMounted(async () => {
  await fetchUser();
  console.log('Mounted and fetching user data...');
});

const fetchUser = async () => {
  const token = store.getters.getToken; // Get the token from the store
  if (!token) {
    console.error('No token found. Redirecting to login.');
    router.push('/login');
    return;
  }

  const userId = route.params.id;
  console.log('Fetching user data for ID:', userId); // Debugging log

  try {
    const response = await axios.get(`/api/admin/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      user.value = response.data;
      console.log('User data loaded:', user.value); // Debugging log
    } else {
      console.error('User data is empty or invalid.');
      router.push('/admin-dashboard');
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    alert('Failed to load user data.');
    router.push('/admin-dashboard');
  }
};

// Modify addRow function to add multiple rows
const addRows = () => {
  if (!user.value) return; // Guard clause if user is not loaded
  for (let i = 0; i < rowCount.value; i++) {
    user.value.rows.push({
      productName: '',
      keyword: '',
      startRank: '',
      todayRank: '',
      midNumber: '',
      productNumber: '',
      notes: '',
      startDate: '',
      endDate: '',
    });
  }
};

// Function to delete rows based on the "from" and "to" input
const deleteRows = () => {
  if (!user.value) return; // Guard clause if user is not loaded
  const startIndex = deleteFrom.value - 1; // Convert to zero-based index
  const endIndex = deleteTo.value - 1; // Convert to zero-based index

  if (startIndex >= 0 && endIndex < user.value.rows.length && startIndex <= endIndex) {
    user.value.rows.splice(startIndex, endIndex - startIndex + 1);
    console.log(`Deleted rows from ${deleteFrom.value} to ${deleteTo.value}`);
  } else {
    alert('Invalid row range');
  }
};

// Save the updated user data
const saveUser = async () => {
  const token = store.getters.getToken; // Get the token from the store
  if (!token || !user.value) return; // Guard clause if token or user is missing

  const userId = route.params.id;
  console.log('Saving user data for ID:', userId); // Debugging log

  try {
    await axios.put(`/api/admin/users/${userId}`, user.value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    alert('User data updated successfully.');
    router.push('/admin-dashboard');
  } catch (error) {
    console.error('Error updating user data:', error);
    alert('Failed to update user data.');
  }
};

// Navigate back to the Admin Dashboard
const goBack = () => {
  router.push('/admin-dashboard');
};
</script>

<style scoped>
/* Page heading should be clearly outside the container */
.page-heading {
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0 0 20px; /* Adjust margins as necessary */
  color: rgb(255, 255, 255);
  position: fixed; /* Fixes the heading at the top */
  top: 20px;
  left: 20px;
  z-index: 1000; /* Ensure it's above other elements */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Adds a subtle shadow to the text */
}

.edit-user-container {
  position: relative;
  z-index: 1;
  max-width: 95%; /* Adjusted to 95% to give space on the sides */
  margin: 100px auto 40px auto; /* Added 100px margin on top to avoid overlapping the heading */
  padding: 30px; /* Padding inside the container */
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.add-rows-container,
.delete-rows-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.input-button-wrapper {
  display: flex;
  gap: 10px; /* Adds space between the input and the button */
  margin-left: 20px; /* Space between the label and the input/button */
}

input[type="number"] {
  width: 80px; /* You can adjust this to control the input size */
  padding: 5px;
}

button {
  padding: 5px 10px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 5px 5px;
}

.styled-table th:nth-child(1),
.styled-table td:nth-child(1) {
  width: 3%;
  text-align: center;
}

.styled-table th:nth-child(2),
.styled-table td:nth-child(2) {
  width: 32%;
}

.styled-table th:nth-child(3),
.styled-table td:nth-child(3) {
  width: 10%;
}

.styled-table th:nth-child(4),
.styled-table td:nth-child(4) {
  width:4%;
}

.styled-table th:nth-child(5),
.styled-table td:nth-child(5) {
  width: 4%;
}

.styled-table th:nth-child(6),
.styled-table td:nth-child(6) {
  width: 10%;
}

.styled-table th:nth-child(7),
.styled-table td:nth-child(7) {
  width: 10%;
}

.styled-table th:nth-child(8),
.styled-table td:nth-child(8) {
  width: 25%;
}

.styled-table th:nth-child(9),
.styled-table td:nth-child(9) {
  width: 150px;
}

.styled-table th:nth-child(10),
.styled-table td:nth-child(10) {
  width: 150px;
}

.styled-table th {
  background-color: rgba(192, 192, 192, 0.5); /* Light grey with 50% opacity */
  color: black;
  text-align: center;
}

.styled-table tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tr:nth-of-type(even) {
  background-color: rgba(211, 211, 211, 0.3);
}

.styled-table tr:last-of-type {
  border-bottom: 2px solid rgba(192, 192, 192, 0.5);
}

.styled-table input[type='text'],
.styled-table input[type='number'],
.styled-table input[type='date'] {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* For fixed buttons at the bottom */
.buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.buttons button {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  flex-grow: 1;
  max-width: calc(90% / 2);
}

.buttons button:first-of-type {
  background-color: #a5d6a7; /* Pastel green */
}

.buttons button:last-of-type {
  background-color: #90caf9; /* Pastel blue */
}

.buttons button:hover {
  filter: brightness(0.9); /* Darken on hover */
}
</style>
